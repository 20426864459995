<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" lg="1" md="1" sm="1" align-self="center">
        <GoBack link="/purchase/warehouse" />
      </v-col>

      <v-col cols="12" lg="11" md="11" sm="11" align-self="center">
        <v-card elevation="2">
          <v-breadcrumbs :items="items" divider="/">
            <template v-slot:[`item`]="{ item }">
              <v-btn
                v-if="item.back == true"
                link
                :to="item.to"
                text
                color="blue"
              >
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>

              <label v-else>
                {{ item.text }}
              </label>
            </template>
          </v-breadcrumbs>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <base-material-card
          :title="$vuetify.lang.t('$vuetify.warehouseGoods')"
          icon="mdi-apple-finder"
        >
          <v-sheet>
            <v-toolbar-items class="d-flex align-center">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.search')"
                v-model="search"
                append-icon="mdi-magnify"
              />

              <v-spacer />

              <v-btn
                v-if="permissionCheck('add')"
                color="#3d5afe"
                elevation="2"
                @click="handlePrintDialog"
              >
              
                <v-icon left>mdi-printer</v-icon>
                {{ $vuetify.lang.t("$vuetify.print") }}
              </v-btn>
            </v-toolbar-items>
          </v-sheet>

          <GeneralTable
            :head="headers"
            :data="datas"
            :search="search"
            :dialog.sync="dialog"
          />
        </base-material-card>

        <!-- <productCRUD
          :dialog.sync="dialog"
          :myObj="obj"
          @handleData="handleProduct"
        /> -->

        <PrintTable
          :dialogPrint.sync="dialogPrint"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
//TODO missing batch upload

import { warehouseGoods, warehouseInfo } from "@/api/purchase";

const newSearchForm = () => {
  return {
    skip: 0,
    limit: 500000,
    warehouse_id: "",
    goods_type: 1,
    goods_id: "",
  };
};

export default {
  name: "goods",
  components: {
    GeneralTable: () => import("@/components/tableImg"),
    GoBack: () => import("@/components/gobackCard"),
    //stockInCRUD: () => import("@/components/purchase/stockInCRUD"),
    PrintTable: () => import("@/components/purchase/printTable"),
  },
  data() {
    return {
      avatar: {},
      dialog: false,
      dialogPrint: false,
      items: [
        { text: this.$vuetify.lang.t("$vuetify.home") },
        { text: this.$vuetify.lang.t("$vuetify.purchase") },
        { text: this.$vuetify.lang.t("$vuetify.warehouseGoods") },
      ],
      search: "",
      datas: [],
      headers: [
        {
          text: this.$vuetify.lang.t("$vuetify.itemNo"),
          value: "itemNo",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.productPicture"),
          width: "20",
          value: "avatar",
          sortable: false
        },
        {
          text: this.$vuetify.lang.t("$vuetify.goodsCode"),
          width: "250",
          value: "goods_info.other_code",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.goodsName"),
          value: "goods_info.name",
          width: "300",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.category"),
          // value: "goods_info.type"
          value: "category",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.unit"),
          value: "goods_info.unit",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.stockQty"),
          value: "inventory",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.lastUpateTime"),
          value: "last_time",
        },
      ],
      searchForm: newSearchForm(),
    };
  },
  methods: {
    getData() {
      warehouseGoods(this.searchForm)
        .then((data) => {
          this.datas = [...data.items];

          this.datas.forEach((p, k) => {
            p.avatar = p.goods_info.image;
            p.category = p.goods_info.type;
            p.itemNo = k + 1;
          });
        })
        .catch((err) => console.log(err));
    },
    handleDialog(obj) {
      this.dialog = true;
      this.obj = obj;
    },
    handlePrintDialog() {
      this.dialogPrint = true;
    },
    BackToStockList() {
      this.$router.push("/purchase/warehouse");
    },
  },
  mounted() {
    this.searchForm.warehouse_id = this.$route.params.id;
    this.getData();
  },
};
</script>