import request from '@/utils/request'

// 仓库列表
export function warehouseList(data) {
  return request({
    url: '/warehouse/list',
    method: 'post',
    data: data
  })
}

export function warehouseInfo(id) {
  return request({
    url: '/warehouse/info/' + id,
    method: 'get'
  })
}

// 添加仓库
export function warehouseUpsert(data) {
  return request({
    url: '/warehouse/upsert',
    method: 'post',
    data: data
  })
}

// 删除仓库
export function warehouseDel(id) {
  return request({
    url: '/warehouse/delete/' + id,
    method: 'delete'
  })
}

export function warehouseGoods(data) {
  return request({
    url: '/warehouse/goods',
    method: 'post',
    data: data
  })
}

export function warehousLog(data) {
  return request({
    url: '/warehouse/log',
    method: 'post',
    data: data
  })
}

export function pickingList(data) {
  return request({
    url: '/receive_order/list',
    method: 'post',
    data: data
  })
}

export function pickingUpsert(data) {
  return request({
    url: '/receive_order/upsert',
    method: 'post',
    data: data
  })
}

export function pickingCheck(data) {
  return request({
    url: '/receive_order/check',
    method: 'post',
    data: data
  })
}

export function pickingCancel(data) {
  return request({
    url: '/receive_order/cancel',
    method: 'post',
    data: data
  })
}

export function pickingInfo(id) {
  return request({
    url: '/receive_order/info/' + id,
    method: 'get'
  })
}

export function purchaseList(data) {
  return request({
    url: '/purchase_order/list',
    method: 'post',
    data: data
  })
}
export function purchaseUpser(data) {
  return request({
    url: '/purchase_order/upsert',
    method: 'post',
    data: data
  })
}

export function purchaseInfo(id) {
  return request({
    url: '/purchase_order/info/' + id,
    method: 'get'
  })
}

export function checkPurchase(data) {
  return request({
    url: '/purchase_order/check',
    method: 'post',
    data: data
  })
}

export function receivePurchase(data) {
  return request({
    url: '/purchase_order/receive',
    method: 'post',
    data: data
  })
}

export function deletePurchase(id) {
  return request({
    url: '/purchase_order/delete/' + id,
    method: 'delete'
  })
}

export function cancelPurchase(data) {
  return request({
    url: '/purchase_order/cancel',
    method: 'post',
    data: data
  })
}
export function postInfoPurchase(data) {
  return request({
    url: '/purchase_order/post_info',
    method: 'post',
    data: data
  })
}

export function checkList(data) {
  return request({
    url: '/receive_order/list',
    method: 'post',
    data: data
  })
}

export function upsertReceive(data) {
  return request({
    url: '/receive_return/upsert',
    method: 'post',
    data: data
  })
}

export function checkReceive(data) {
  return request({
    url: '/receive_return/check',
    method: 'post',
    data: data
  })
}

export function cancelReceive(data) {
  return request({
    url: '/receive_return/cancel',
    method: 'post',
    data: data
  })
}

export function infoReceive(id) {
  return request({
    url: '/receive_return/info/' + id,
    method: 'get'
  })
}

export function receive_order(data) {
  return request({
    url: '/receive_order/ex',
    method: 'post',
    data: data
  })
}

export function putaway(data) {
  return request({
    url: '/receive_return/putaway',
    method: 'post',
    data: data
  })
}

export function getTemp(data) {
  return request({
    url: '/base_data/info/' + data,
    method: 'get'
  })
}

export function pucharPdf(data) {
  return request({
    url: '/purchase_order/pdf',
    method: 'post',
    data: data
  })
}

export function supplierOrder(data) {
  return request({
    url: '/supplier/order',
    method: 'post',
    data: data
  })
}
// 采购单统计
export function purchaseOrderStat2(data) {
  return request({
    url: '/purchase_order/stat2',
    method: 'post',
    data: data
  })
}
// 订单统计
export function orderStat2(data) {
  return request({
    url: '/order/stat2',
    method: 'post',
    data: data
  })
}
